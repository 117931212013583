import Web3 from 'web3';

async function loadWeb3() {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
}


async function loadWeb3Data(contract_address, contract_abi) {
  await loadWeb3();
  try {
      const web3 = window.web3;
      if (!web3) {
          throw new Error('Web3 is not initialized');
      }

      const accounts = await web3.eth.getAccounts();
      if (!accounts.length) {
          throw new Error('No accounts found');
      }

      const contract = new web3.eth.Contract(contract_abi, contract_address);
      const totalSupply = await contract.methods.totalSupply().call();

      return {
          contract: contract,
          account: accounts[0],
          address: contract_address,
          totalSupply: totalSupply
      };
  } catch (err) {
      console.error('Error loading blockchain data:', err);
      return {
          contract: undefined,
          account: '',
          address: '',
          totalSupply: 0
      };
  }
}



export default loadWeb3Data;


/*
    loadWeb3Data("0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49", CryptoPodz.abi).then(function(res){
      console.log(res)
    })
*/