import React, {useState} from 'react';
import classes from './SectionTwo.module.css';
import PodzGen2 from '../../../assets/images/BattlePodz/Element2.gif';

import Desc1 from '../../../assets/images/BattlePodz/ClassesInsignia_00022.png';
import Desc2 from '../../../assets/images/BattlePodz/ClassesInsignia_0002.png';
import Desc3 from '../../../assets/images/BattlePodz/ClassesInsignia_00023.png';
import Desc1_webp from '../../../assets/images/battlepodz_webp/ClassesInsignia_00022.webp';
import Desc2_webp from '../../../assets/images/battlepodz_webp/ClassesInsignia_0002.webp';
import Desc3_webp from '../../../assets/images/battlepodz_webp/ClassesInsignia_00023.webp';

 import MintingPage from '../Mint/MintingPage';

const SectionTwo = () => {

	const [showMintForm, setShowMintForm] = useState(false);

	return (
		<div className={classes.SectionTwo} id="section2">
			<div className={classes.Container}>
				<div className={classes.Text}>
					<div className={classes.BuyPodOpenSea}>
						<div>BUY A POD</div>
						<div>The public sale is live, please click the mint button to proceed.</div>
						<div>
 							{ new Date().getTime()/1000 < 1648220400 ?
 								<button>
 									COMING SOON
 								</button>
 							:
 							<button onClick={()=>setShowMintForm(true)}>
 								MINT
 							</button> }
 						</div>
					</div>
					<div className={classes.PodzWar}>
						<div className={classes.WarImg}>
							<img src={PodzGen2} alt="Generation Two Podz" />
						</div>
						<div className={classes.WarText}>
							<div>GENERATION TWO</div>
							<div>
								<p>The BattlePodz are the successors of the original CryptoPodz.</p>
								<p>Packed with different abilities, these new podz will be the main playable characters enabling you to navigate and interact inside PodzWorld.</p>
								<p>Each currency now has a party of 5 members, Tank, Healer, Rangers (I, X, V)</p>
								<div className={classes.PodzDesc}>
									<div>
										<picture>
											<source srcset={Desc1_webp} />
											<img src={Desc1} alt="tank - CryptoPodz" />
										</picture>
										TANK
									</div>
									<div>
										<picture>
											<source srcset={Desc2_webp} />
											<img src={Desc2} alt="healer - CryptoPodz" />
										</picture>
										HEALER
									</div>
									<div>
										<picture>
											<source srcset={Desc3_webp} />
											<img src={Desc3} alt="ranger - CryptoPodz" />
										</picture>
										RANGER
									</div>
								</div>
								<p><span>GEN II - 500</span></p>
								<div className={classes.Editions}>
									<p><span>MK I </span>- 75 Editions</p>
									<p><span>MK II </span>- 175 Editions</p>
									<p><span>MK III </span>- 250 Editions</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.Image}>
				<div className={classes.ImageContainer}>
					<div className={classes.BattlePodzVideo}>
						<iframe src="https://www.youtube.com/embed/xldIoP_SVk4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				</div>
			</div>
			 {showMintForm ? <MintingPage close={()=>setShowMintForm(false)} /> : null}
		</div>
	);
}

export default SectionTwo;