import React, { useState, useEffect } from 'react';
import Logo from '../../../assets/images/logos/Logo.png';
import LoadingIcon from '../../../assets/images/compressed/Loading.gif';
import DiscordIcon from '../../../assets/images/others/discord-icon.png';
import DiscordIconWebp from '../../../assets/images/others_webp/discord-icon.webp';
import classes from './Header.module.css';

const Header = () => {

    const [hamburger, setHamburger] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const Links = {
        cryptopodz: "/",
        battlepodz: "/battlepodz",
        archive: "/archive",
        podzworld: "/podzworld"
    }

    const currentURL = window.location.pathname;

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.screen.width > 768) {
                setHamburger(false);
            } else {
                setHamburger(true)
            }
        });
        window.addEventListener('load', function () {
            if (window.screen.width > 768) {
                setHamburger(false);
            } else {
                setHamburger(true)
            }
            if (window.location.pathname === '/') {
                document.getElementById("coverScreen").style.display = "none"
            }
        });

    }, [])

    return (
        <div className={classes.Header}>
            {window.location.pathname === '/' ? <div id="coverScreen" className={classes.LockOn}>
                <img src={LoadingIcon} alt="Loading" />
            </div> : null}
            <div className={classes.HeaderContainer}>
                <div className={classes.Container}>
                    <div className={classes.Logo}>
                        <a href="/"><img src={Logo} alt="Logo" /></a>
                    </div>
                    {hamburger ?
                        <div className={menuOpen ? [classes.Hamburger, classes.Change].join(' ') : classes.Hamburger} onClick={() => setMenuOpen(!menuOpen)}>
                            <div className={classes.Bar1}></div>
                            <div className={classes.Bar2}></div>
                            <div className={classes.Bar3}></div>
                        </div>
                        :
                        <div className={classes.Menu}>
                            <a href={Links.cryptopodz} className={currentURL === Links.cryptopodz ? classes.Active : null}>
                                <div className={classes.MenuItem}>
                                    CRYPTOPODZ</div></a>
                            <a href={Links.battlepodz} className={currentURL === Links.battlepodz ? classes.Active : null}>
                                <div className={classes.MenuItem}>
                                    BATTLEPODZ</div></a>
                            <a href={Links.podzworld} className={currentURL === Links.podzworld ? classes.Active : null}>
                                <div className={classes.MenuItem}>
                                    PODZWORLD</div></a>
                            <a href={Links.archive} className={currentURL === Links.archive ? classes.Active : null}>
                                <div className={classes.MenuItem}>
                                    ARCHIVE</div></a>
                            <a href="https://discord.gg/6HnPYCfnej" className={classes.DiscordIcon} target="_blank" rel="noreferrer"><div className={classes.MenuItem}>
                                <picture>
                                    <source srcSet={DiscordIconWebp} />
                                    <img src={DiscordIcon} alt="Discord icon" />
                                </picture>
                            </div></a>
                        </div>}
                </div>
                {hamburger && menuOpen ?
                    <div className={[classes.Menu, classes.Mobile].join(' ')}>
                        <a href={Links.cryptopodz} className={currentURL === Links.cryptopodz ? classes.Active : null}>
                            <div className={classes.MenuItem}>
                                CRYPTOPODZ</div></a>
                        <a href={Links.battlepodz} className={currentURL === Links.battlepodz ? classes.Active : null}>
                            <div className={classes.MenuItem}>
                                BATTLEPODZ</div></a>
                        <a href={Links.podzworld} className={currentURL === Links.podzworld ? classes.Active : null}>
                            <div className={classes.MenuItem}>
                                PODZWORLD</div></a>
                        <a href={Links.archive} className={currentURL === Links.archive ? classes.Active : null}>
                            <div className={classes.MenuItem}>
                                ARCHIVE</div></a>
                        <a href="https://discord.gg/6HnPYCfnej" className={classes.DiscordIcon} target="_blank" rel="noreferrer"><div className={classes.MenuItem}>
                            <picture>
                                <source srcSet={DiscordIconWebp} />
                                <img src={DiscordIcon} alt="Discord icon" />
                            </picture>
                        </div></a>
                    </div>
                    : null}
            </div>
        </div>
    );
}

export default Header;