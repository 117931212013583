import React from 'react';
import classes from './SectionTwo.module.css';

const SectionTwo = () => {

  return (
    <div className={classes.SectionTwo}>
      <iframe 
        className={classes.Iframe} 
        width="100%" 
        height="640" 
        frameBorder="0" 
        allow="xr-spatial-tracking; gyroscope; accelerometer" 
        allowFullScreen 
        scrolling="no" 
        src="https://kuula.co/share/hmPpS?logo=-1&info=0&fs=1&vr=1&zoom=1&thumbs=-1&margin=10&inst=0"
        title="PodzWorld Virtual Tour">
      </iframe>
    </div>
  );
}

export default SectionTwo;