import React,{useEffect} from "react"
import classes from './SectionNine.module.css'
import twitter from '../../../assets/images/others/twitter2.svg';
import instagram from '../../../assets/images/others/instagram.svg';
import DiscordIcon from '../../../assets/images/others/discord-icon.png';
import DiscordIconWebp from '../../../assets/images/others_webp/discord-icon.webp';
import SendIcon from '../../../assets/images/others/send.png';

const SectionNine=()=>{

  useEffect(()=>{
    formSubmit();
  },[])

  const formSubmit = () => {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    const scriptURL = 'https://script.google.com/macros/s/AKfycbyuVzGvaGbLIGjGlYY3uJKxAEeSDVihiVQO36F18IpsIf7vktOjrlbh_3y4NE-3Mxs5/exec';
    const form = document.forms['submit-to-google-sheet'];
    const input = document.getElementById("emailInput");
    const button = document.getElementById("submit-form-button");
    const label = document.getElementById("labelId");

    form.addEventListener('submit', e => {
      e.preventDefault();
      if(!pattern.test(input.value)){
        alert("Invalid Email Id");
        return false;
      }else{
        label.innerHTML = "wait...";
        button.style.display = "none";
        fetch(scriptURL, { method: 'POST', body: new FormData(form)})
          .then(response => {
            console.log('success!');
            input.value = "";
            label.innerHTML = "success";
            label.style.color = "#6df47e";
            setTimeout(function(){
              label.style.color = "#FFF";
              label.innerHTML = "stay tuned";
              button.style.display = "inline-block";
            },3000)
          })
          .catch(error => {
            console.error('error!')
            input.value = "";
            label.innerHTML = "error";
            label.style.color = "#fc4448";
            setTimeout(function(){
              label.style.color = "#FFF";
              label.innerHTML = "stay tuned";
              button.style.display = "inline-block";
            },3000)
          })
        }
      })
  }


// ... existing code ...
return(
  <div className={classes.SectionNine}>
    <div className={classes.Container} id="section9">
      <div className={classes.TextContainer}>
          <p>CryptoPodz Contract Address:</p>
          <p><a href="https://etherscan.io/address/0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49#code" target="_blank" rel="noreferrer">0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49</a></p>
      </div>
      <div className={classes.EmailCollection}>
        <form name="submit-to-google-sheet" action="" method="post">
          <div>
            <label id="labelId">stay tuned</label>
            <div className={classes.EmailContainer}>
            <input type="email" name="email" id="emailInput" placeholder="Email Address" />
            <button type="submit" id="submit-form-button"><img src={SendIcon} alt="send icon" /></button>
            </div>
          </div>
        </form>
        <div className={classes.ImageContainer}>
            <div className={classes.TwitterContainer}>
               <a href="https://twitter.com/TheCryptoPodz" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter_image"/></a>
            </div>
            <div className={classes.InstagramContainer}>
                <a href="https://www.instagram.com/cryptopodz" target="_blank" rel="noreferrer"><img src={instagram} alt="instagram_image"/></a>
            </div>
            <div className={classes.DiscordContainer}>
                <a href="https://discord.gg/6HnPYCfnej" target="_blank" rel="noreferrer">
                  <picture>
                    <source srcSet={DiscordIconWebp} />
                    <img src={DiscordIcon} alt="discord-icon" />
                  </picture>
                </a>
            </div>
        </div>
        <p>© 2021-2024 CryptoPodz</p>
      </div>
    </div>
  </div>
)

}
export default SectionNine;
