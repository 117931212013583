import React from "react";
import classes from './SectionThree.module.css';

import PodzCoin from '../../../assets/images/BattlePodz/Element4.gif';

const SectionThree=()=>{
    const Render=[
        /*{
            title:"NO POOP COINS",
            content:"As long-term fanatics of the space, the projects we portray in this collection are ones we respect."
        },*/
        {
            title:"NEW COLLECTION, NEW CONTRACT",
            content:"BattlePodz is created on a new separate smart-contract. The original Gen-I collection will always remain untouched."
        },
        {
            title:"EXPANDING THE COMMUNITY",
            content:"This new collection has a total of 500 tokens, while retaining the same cryptocurrency projects as Gen-I, they are now each represented by 5 different subclasses."
        },
        {
            title: "REWARDING THE OGs",
            content: "BattlePodz will be available to CryptoPodz collectors for free via airdrop. The airdrop will be happening a few hours prior to the sale based on an instant snapshot of all the current CryptoPodz collectors. Both the airdrop and sale will be completely randomized."
        },
        {
            title:"SURROGATES",
            content:"BattlePodz also represent avatars and playable characters inside PodzWorld. While the Gen-I won't be playable, their ownership will play a big part in the simulation."
        },
        {
            title:"A NEW FRONTIER",
            content:"PodzWorld is our first dive into a web-3 powered persistent online world. We are excited to see you all inside, building it out over time with your contribution."
        }
    ]

    return(
    <div className={classes.SectionThree}>
        <div className={classes.Container} id="section3">
            <div className={classes.ParaContainer}>
                <p>MORE FACTS</p>
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
            <div className={classes.ContentRendering}>
                {Render.map((item, index)=>{
                         return <div className={classes.ObjectContainer}>
                              <p>{index === 2 ? <>{item.title.slice(0,-1)}<span>s</span></> : item.title}</p>
                              <p>{item.content}</p>
                              </div>
                    })}
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
            <div className={classes.PodzCoin}>
                <img src={PodzCoin} alt="Podz Coin" />
            </div>
        </div>
    </div>
    )
}
export default SectionThree