import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import './AccountPage.css'; // Import the CSS file

// Define Button component
function Button({ children, variant = 'primary', className = '', ...props }) {
  return (
    <button
      className={`button ${variant === 'primary' ? 'button-primary' : 'button-secondary'} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}

// Define Input component
function Input({ label, id, className = '', ...props }) {
  return (
    <div className="input-group">
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <input
        id={id}
        className={`input-field ${className}`}
        {...props}
      />
    </div>
  );
}

async function createPlayer(playerData) {
  const response = await fetch('/.netlify/functions/createPlayer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(playerData),
  });

  if (!response.ok) {
    throw new Error('Failed to create player');
  }

  return response.json();
}

async function getPlayer(email) {
  const response = await fetch(`/.netlify/functions/getPlayer?email=${encodeURIComponent(email)}`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch player');
  }

  const data = await response.json();
  return data.length > 0 ? data[0] : null; // Ensure correct check
}
function AccountPage() {
  const location = useLocation();
  const walletAddress = location.state?.walletAddress || '0x...';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }

    try {
      setIsLoading(true);
      setError('');

      const existingPlayer = await getPlayer(email);

      if (existingPlayer) {
        setError('An account with this email already exists');
        return;
      }

      await createPlayer({
        email,
        password,
        walletAddress,
      });

      setIsRegistered(true);
    } catch (err) {
      const errorMessage = err instanceof Error 
        ? err.message 
        : 'Failed to create account. Please try again.';
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (isRegistered) {
    return (
      <div className="account-page">
        <div className="success-message-container">
          <h2>Success!</h2>
          <p>Your account has been registered successfully.</p>
          <p>
            If you haven't already, you can download the game <a href="https://cryptopodz.com/podzworld">here</a>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="account-page">
      <div className="account-container">
        <h1 className="account-title">Welcome to PodzWorld</h1>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="wallet-info">
            <p className="wallet-owner">USER IS AUTHENTICATED</p>
            <p className="wallet-address">Wallet ID: {walletAddress}</p>
          </div>
          
          <p className="form-instructions">
            Please fill in email and password to create a game account. 
            You may use these credentials to log in the game world.
          </p>

          <Input
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isLoading}
          />

          <Input
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading}
            minLength={6}
          />

          {error && (
            <p className="error-message">{error}</p>
          )}

          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Creating Account...' : 'Sign Up'}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AccountPage;