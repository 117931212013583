import React from "react";
import classes from './SectionNine.module.css';
import twitter from '../../../assets/images/others/twitter2.svg';
import instagram from '../../../assets/images/others/instagram.svg';
import DiscordIcon from '../../../assets/images/others/discord-icon.png';
import DiscordIconWebp from '../../../assets/images/others_webp/discord-icon.webp';

const SectionNine = () => {
  return (
    <div className={classes.SectionNine}>
      <div className={classes.Container} id="section9">
        <div className={classes.TextContainer}>
          <p>BattlePodz Contract Address:</p>
          <p>
            <a
              href="https://etherscan.io/address/0x41828e6edcc8173f829989993855c51c78de66c6#code"
              target="_blank"
              rel="noreferrer"
            >
              0x41828e6edcc8173f829989993855c51c78de66c6
            </a>
          </p>
        </div>
        <div className={classes.EmailCollection}>
          <p>© 2021-2024 CryptoPodz</p>
          <div className={classes.ImageContainer}>
            <div className={classes.TwitterContainer}>
              <a
                href="https://twitter.com/TheCryptoPodz"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter_image" />
              </a>
            </div>
            <div className={classes.InstagramContainer}>
              <a
                href="https://www.instagram.com/cryptopodz"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram_image" />
              </a>
            </div>
            <div className={classes.DiscordContainer}>
              <a
                href="https://discord.gg/6HnPYCfnej"
                target="_blank"
                rel="noreferrer"
              >
                <picture>
                  <source srcSet={DiscordIconWebp} />
                  <img src={DiscordIcon} alt="discord-icon" />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionNine;