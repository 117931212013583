import React, { Component } from 'react';
import Web3 from 'web3';
import CryptoPodz from '../../abis/CryptoPodz.json';
import classes from './BuyFunction.module.css';

class BuyFunction extends Component {
    constructor(props) {
        super(props);
        this.state = {
		  account: '',
		  contract: null,
		  totalSupply: 0,
		  price: 0,
		  address: '',
		  myTokens: [],
		  formShow: false,
		  whitelisted: false,
		  baseURI: 'https://ipfs.io/ipfs/Qmcq77c6G2JUT6NJfG4Lh7tHSdympFy3vDsbmHrnpERkNB/',
		  openseaURI: '',
		  maxDisplay: 10,
		  allHashes: [
    {
        "name": "ETC",
        "full_name": "ETH Classic",
        "image": "ipfs://QmV1ZgpfBaKEnzPJHFDpCvqjtngrXjLLs87s88StNhRhKX",
        "hashed": "79357e83ec242c80e0c51e3242a78dea6bad4f8953d2a10ba26a062b0afdce56",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "LSK",
        "full_name": "Lisk",
        "image": "ipfs://Qmbzj6nsEQLq9GieKHckXGwGEL3oFFcssN9eTfnQfq8LgQ",
        "hashed": "9a771a2b3faa64d38615d52db0e88714d63d708783da6eb3c2bfde2cfe015ac1",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "XMR",
        "full_name": "Monero",
        "image": "ipfs://QmYcYtHjottBghLZXQ6TriDcNWyfKZjC9KR4uToWqzbXDA",
        "hashed": "9d1bf28057bb8e93d880fb3f450698eacdab41dfb1eca928731e10147c4d909a",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "BAL",
        "full_name": "Balancer",
        "image": "ipfs://QmYbJcDERKMqwbzNefT4hZiriSVB6ebwcvvgWVA4sCXgei",
        "hashed": "da185b1d4692ca1b475246fd05fcb8fdb4efca8e4bdc30573059f96decb06ab7",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "QTUM",
        "full_name": "QTUM",
        "image": "ipfs://QmUHKpzgqufrVX8NZjp9Tgtir1SswfcgCL5SZMRAGsexsT",
        "hashed": "7ea01968a698878cbf5384f78ee37b57611271f79e49d7f2a471de57056f3f18",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DASH",
        "full_name": "Dash",
        "image": "ipfs://QmbH2sEfDW7CcbAPAgG5Gide5JEnyYk8gyucYDyuSmRW77",
        "hashed": "fffc4b65d0aeaeeb72e6c8204c655f6f92b0c5618739b61a32c63c575039ac8c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "XLM",
        "full_name": "Stellar",
        "image": "ipfs://QmYuj5EVYqvWh6ZPUbszNy4dEtB9Gh7NS2Bwe5PQbwqeeG",
        "hashed": "2c3b099ff3556bb756f0e73842f05bede25916a99619203cba1f17f37e47ce0c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "AR",
        "full_name": "ARweave",
        "image": "ipfs://QmctwWPyEdWYycy4bmdAb31cJLZYo1jLphmXeqDycYu3Hb",
        "hashed": "c8fdf89c4ce0cd060484de21737efc062dce500f225ea5cb89959f60921c1ba2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "KSM",
        "full_name": "Kusama",
        "image": "ipfs://QmPVUbQga7XMcnzmBK29r38UoLp6oo76pTTyrEbYD5wTej",
        "hashed": "28d1b01e178ffc47c1ac05eb88eaa0c80639c38bbd02a1cb9de267747812866e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BNB",
        "full_name": "Binance",
        "image": "ipfs://QmYXGhmNADiTRHPgGrWAHaE7PZH6qyegApXS6A84ZxkFvL",
        "hashed": "4f34671dafa9a61e3875c1e5c5e9774477f86a5620bb4e47901c95dc4fe9aac8",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "EGLD",
        "full_name": "Elrond",
        "image": "ipfs://QmNbs5W2B4VSy54d3e6S8q4KoQUxTjGCDuKwUu37Tq53u4",
        "hashed": "07a84a8a465306b53788d1da5caef8bb98f0a6d5826eeb02a99b2bb2d0d71980",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DAI",
        "full_name": "Dai",
        "image": "ipfs://QmTvXmSgFNgoGkuCPDRgWtfAJZ7NWSC6LhUpzNncFb2XfT",
        "hashed": "915f11d11502b55d074dd8431255a68dcb351ae996f6f3a65be89552d604c79e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "NXT",
        "full_name": "NXT",
        "image": "ipfs://QmQQU2KUPo6wCJKfqwnQknmiUELniB18ToS2CkgyC2Jeki",
        "hashed": "f550fcc14b6e2bccea387ac8f08128d111d4ba51eb568773b5f95832aa66e050",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "CHZ",
        "full_name": "Chiliz",
        "image": "ipfs://QmX8f2QsCANh552CQ5ZMvc7opF4ocmtdwhjfJzGESFA5Bz",
        "hashed": "a4c18773ab7e1773e8f884baa9acffff9a40cdf7622a15c703d7fd3a3813f8b2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "XTZ",
        "full_name": "Tezos",
        "image": "ipfs://QmWMfRZFXfNcNp68qFi2pB6aZuXq1eEg5gAVj4ajggpBs8",
        "hashed": "24656c7c39d71b64049447b345bf722f5bb6a2936988be4fb49438a7fcf86f6b",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "AMP",
        "full_name": "AMP",
        "image": "ipfs://QmfK9yXTqcgpR9BfSBLcxXJPHA9KzVy91aDdLHFvkKpE7F",
        "hashed": "71275dc54d97f0bd3df28fa4a10d305a0a0276040a66b38c85800384819435e9",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ETH",
        "full_name": "Ethereum",
        "image": "ipfs://QmbXE9GMZLnnUJgGfWErdBFcs2fpBUwabXZy8GXtKoJJuk",
        "hashed": "2794918a7cfb857b4c49234c5a9ca8ce479ff0be5622579f58ec448a1dad817d",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "4"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "DCR",
        "full_name": "Decred",
        "image": "ipfs://QmPeVE9ktYDXG3PyAH5u4Z77BLsuZtoJoVXodvDF6kF8X4",
        "hashed": "05d0e30a03586ac6cb106ec587286e0d3fa822d376d39da729c5fa8365d3db69",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ARK",
        "full_name": "ARK",
        "image": "ipfs://QmXNnf2tvk6KE1RJCoZenU2wCrZMtk9FbN2usgDqBUKnwV",
        "hashed": "386f4cc83c518a86427e359295e328d9b86d48e69c7c52373631896a9f5ff42c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "LINK",
        "full_name": "Chainlink",
        "image": "ipfs://QmQyrVyAU4zYzyj77U2LUWZ4ya2awqBD7VwA18MnShP6ME",
        "hashed": "de3298bfa4756bb1aec70386131e670a30a6b84267661a72f02d272538bae074",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ICP",
        "full_name": "Internet Computer",
        "image": "ipfs://QmNXfTumKZmujdVVGYozsVQJYMZUeEXnxMxr5vHi2SBdAo",
        "hashed": "c4f971926e31d60f506f2fa822bba6b3301902cad907cc3c6069bb7eb7055ce5",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BTT",
        "full_name": "Bittorrent ",
        "image": "ipfs://QmbuAttc7YsPuT6Nt4hawYSTt5V4VvARt2bH4DU25KdFT8",
        "hashed": "43b6a26ea2f204543c04e5e72360883835d3c18812de8a7543f7710737c43fa3",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "MIOTA",
        "full_name": "IOTA",
        "image": "ipfs://QmQDRDDojtjvpE8Lt9nzhEiHPaUDZqe5fjd5UCPBiUc8ng",
        "hashed": "8951b95c811288ee94ff20f8ea556fc241ecbd74a7d1de31676dc5f6c6b32b5c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "GLM",
        "full_name": "Golem",
        "image": "ipfs://QmevFsHTyNCF8rdVgM5KoDxxajV7gUSU3SBWvjuwLkEaeN",
        "hashed": "0590933cfed02ad11fabcd3ae0eb1c36325a5a177c7f7f79d8f989e63a5de6f2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "MKR",
        "full_name": "Maker",
        "image": "ipfs://QmSAcuUBtNoEFPHjMjzoDkTLfoKXLU4xFVAMjUWcFYSn4S",
        "hashed": "a4ae6936cc31e4ec9dc79aebbfa5f0a98d29ff50eaf01400d5ef53be19003423",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "SOL",
        "full_name": "Solana",
        "image": "ipfs://QmdH43XMt4x4mrKzwau3cC6Rzut8HJBrRX2Wzua8GTfDkM",
        "hashed": "51de8e31be52e17ea15b36643aa0bb1fa6a12d04df3f39ceb6d352673a2ecc4b",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "LTC",
        "full_name": "Litecoin",
        "image": "ipfs://Qmd2XoE8XPHg1it7VxxvMphYGJBTYGUBpUtYLh6QoaA3UX",
        "hashed": "19638ad275062a7352b5ad0e2cf19c329289f3a569e91aec70b0c4c06c847b8e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "BTC",
        "full_name": "Bitcoin ",
        "image": "ipfs://QmaMjbeWa1CWBbWprMBaUKot1fwHYaSWihGDrZxz9Bv1MG",
        "hashed": "59e9b2a83616abb3bdc4cf74c8401b066f6104e513a2fdf633b75116760d00ab",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "4"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "UNI",
        "full_name": "Uniswap",
        "image": "ipfs://QmTiwdRQ7dcL53KQQnXy1u5d4nhZPBepjsnUWTVa4M2AKn",
        "hashed": "83b3399e15b3d0a5c17f939a90f91c8d2e1ee9c8d4a33644df93e2b7e12a08a1",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ZRX",
        "full_name": "0X",
        "image": "ipfs://QmXRMpf36gSfjcPax9Yh4PXCTJsjqUJpBXeA1hCDFrD5vQ",
        "hashed": "13e4a25b7763ec14d2878dd4e0ac7b9ea47e0b244977f6969b0d897f16c87976",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ZIL",
        "full_name": "Zilliqa",
        "image": "ipfs://QmW32dMjxWqjvNtK6MNz93s49k7SKEujeudztMZQjQbtnP",
        "hashed": "2d1136e01cdd6375f006e79c50b8e8b09dcf32e9a06da1bc93f0dba2cb215b3e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "EOS",
        "full_name": "EOS",
        "image": "ipfs://QmVB59FqXjYZ5uempFLa8Sh7zyRLUWWZPJbSyuXV59sbCB",
        "hashed": "fb866f71b7853dd674f53654a9a0f9c2f955ab4df34cf16660094c20b25ce41c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BAT",
        "full_name": "BAT",
        "image": "ipfs://QmXzJhnD6aiGnuECKMh9Ebp4PvQKUeGYmkmfsPeAV7b5aL",
        "hashed": "f10ddcbff0650f85041165cbb2e087f36dc7b5c7fbc8ea23ce4c6e73574a1726",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DENT",
        "full_name": "Dent",
        "image": "ipfs://QmP8ujTj6NPW5KazoHE7xoGVSXrNmCorhDFA1fDzqpPaFN",
        "hashed": "e6b2f256c75c0147097cdf2fbdbca2b0562749152719f37a58eed2013fe2376c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "CHSB",
        "full_name": "Swiss Borg",
        "image": "ipfs://QmTVTCAhesu4GhXhJMJepWvTuzAee84pK4bPL8eAGQT27F",
        "hashed": "7cee53b51dac49744d3a290001d456e98001f1c309aaf6a37729748d15e2a1fb",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "GRT",
        "full_name": "The Graph",
        "image": "ipfs://QmRRnwqfbCcywVUSznAswD7pGq8Q712teQ5Pt2JjDqfYeA",
        "hashed": "81d18b4707a0d7c4b40890be102d6ad4f13757698adbe451b3298d5f8c575948",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "SUSHI",
        "full_name": "Sushiswap",
        "image": "ipfs://QmerTkoZAvutXYUpaH81SJwNxpWvjtwHd7YAp1V1CcqJce",
        "hashed": "48680ebb88c65f077dcc088d62ce860a7bb4fce231dfc953748ee21f5cda275c",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "STAKE",
        "full_name": "xDAI",
        "image": "ipfs://QmXpmcTFqVD9D99uX3apYvfArsFvurwJw3fWpsU2BS8jMC",
        "hashed": "c53f2d0af166fcf07b951c7223778f496c98eef885ce1942855170d61f345ab6",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "THETA",
        "full_name": "Theta",
        "image": "ipfs://QmWamcor4xnyith5m3JXUgfMtR2umZWRFnfkesufXFVt4w",
        "hashed": "588908af8a9ef9698688742ef08148c6e7830361f4a817e5852bb30ba9f2c605",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "KLAY",
        "full_name": "Klaytn",
        "image": "ipfs://QmVkKNmYQo2UEp3WeXZVF2Fc4ABBRUzUVAn3MQxyXDDAPE",
        "hashed": "25ce09d29b04d34df98a6bef6613537e0f78bf1e34c17af00a01821d9e0d451f",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "USDT",
        "full_name": "Tether",
        "image": "ipfs://QmZm3srU9NSwjcrc3n6WDSSweYE5wyw5F89vyKGzwPKSwF",
        "hashed": "90c1dc4952ac5d18e249c82e1ae53d8f01961f0087db21fa4b915fa64cd20b24",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "OMG",
        "full_name": "Omisego",
        "image": "ipfs://QmdR576P4cBpxav73vTZkqhK9NAaQryoL6RUuZFoZZrzoY",
        "hashed": "6a25fee9990cc87641b85eeb0c1ab2064cfb194c95a3188bdf83befb8059dc9a",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ONT",
        "full_name": "Ontology ",
        "image": "ipfs://QmWJXjT34vtKnEkqoM5RGPYisFBD5jQTZyySa8HfvvzkGH",
        "hashed": "c225c08f56bcaf10335732db29bd2fa3db63d0bc7eac608a47d7a6cbc250299b",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "NMR",
        "full_name": "Numeraire",
        "image": "ipfs://QmPM7LRnUiWABcbU2wsrpRRAaVvF7WxaCqyvgVzLpNoMNM",
        "hashed": "4699e2ee9e0b44569b891be8cfb23d26e39d6180bd99e01204ca60f42461c1ed",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BCH",
        "full_name": "BitcoinCash",
        "image": "ipfs://QmdFkJF11dMXPB4a2TQUnwrqrHofgXWv4742ehVr9Ci3qB",
        "hashed": "4c071e6dba9040d4e9458bcc5a094d75e6e2a42f1b6dc7aeff91c7fcd19e7115",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "STRAX",
        "full_name": "Stratis",
        "image": "ipfs://QmZWdSDUCTywZf1vWtz7vy8pG1c1FA3cE8iLR1R1EVe9hM",
        "hashed": "5154206321f91e8146a56f28fabe4f2ad33f99a6b90137bce08e02b9378e3e38",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "RUNE",
        "full_name": "Thor",
        "image": "ipfs://QmccaQXmAoAz6JXKJhqdhJ8aFYFWRuEwhP722NEKJHk3b7",
        "hashed": "3cc7208aa749070edb6a62e4d8f38a1500d01405c640263bc71f4cffc728b6e5",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "MATIC",
        "full_name": "Polygon",
        "image": "ipfs://QmWDzJXVAwT8pPo1Cii9PV2ihxdnrbfhx8YjNuFLXfP2yf",
        "hashed": "1e84e1ce38904f26046af7255edef4292a1986e56088c7cbdab0f64af0896b4e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ARDR",
        "full_name": "Ardor",
        "image": "ipfs://QmZfSV4gmWkjB2tRj74EVwW2QeH881avso1TBdxWD4CS7o",
        "hashed": "a810e823839e72d45f147e63460508e57e1d3d399ea364ced41eedfdcf1fe303",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "REP",
        "full_name": "Augur",
        "image": "ipfs://QmU6FTnDQQzzYSQjYsvjtY9twEQuUNDaT9G6hEbaKZsPqr",
        "hashed": "41be678317acb98f83d2baa75fcf8fa543157a6bcb14902fdf649c88cd598eb7",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "NEAR",
        "full_name": "Near Protocol",
        "image": "ipfs://QmZXRx4AjLj6gMaVuZpVciwZR3XsW7Ti4Dbh1kErWNfGHz",
        "hashed": "d191da8eeb858cef870cf3533fe2a1be93b1113f95123969f6a299ef5062a931",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "OGN",
        "full_name": "Origin",
        "image": "ipfs://QmYukKhW1GoCPnhmcTV7GmiesFiG1KiudNLWkTN9YudsHT",
        "hashed": "253146d413e5f28000cdd875f2f6ee3a653b8a13adc11f8f8ce36db53a8cd726",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BAND",
        "full_name": "Band Protocol",
        "image": "ipfs://QmUAbJRvJAY5ePmGhgDNQyvhGS11KxYrwbpR3xvci9kjNY",
        "hashed": "53f629595acbd72f8bf0ddd4589a2c3b6cf61aeaaaa7545e3b08606eee4a3ef8",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ALPHA",
        "full_name": "Alpha Finance",
        "image": "ipfs://QmQBSLaWGJ6jNYvGZ9P9sG5GNF7nQP2CxesWdFkptw511T",
        "hashed": "57524604c1f67fc3e457495620d2bee95b7c6e456a3641b6223e3c38b500d843",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "LUNA",
        "full_name": "Terra",
        "image": "ipfs://QmTCiWprcRdfTtRQWVGZ9LDLvmF7vCXkGUsgqKPERjyw5o",
        "hashed": "4f6bcdb7f98eb0c65b498247e19d3a9e66f7e4b960656575a61e499dec70c60e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ADA",
        "full_name": "Cardano",
        "image": "ipfs://QmXYd1Xx6aPFNsjEPQfbW76Bsv7xPx97Hwa1PQwHjFTr4h",
        "hashed": "aab5fc59c922b60ad24d010f6ed9707fd3723658b5525078ae2be1e648fa3320",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "VET",
        "full_name": "Vechain",
        "image": "ipfs://QmTQ4jAHex1nodHhL3GtBnJZTqWScjvb1pW8nzVq7U1uXV",
        "hashed": "9791f3b0a9b86f0a9794d562d6a6d03500614d9899a2bb8cfe99256b9d18a722",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "STEEM",
        "full_name": "Steem",
        "image": "ipfs://QmNYwuShcQTWDP7hAJQUYFsCasXRQmDmVHZwH2kEGVRv1h",
        "hashed": "2f9a58d280522739e794bc37e09e58455e37d2bf3526a0e30e83af7a3407eb0b",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "FTT",
        "full_name": "FTX",
        "image": "ipfs://QmUT2QLHQzeEeFMdEz182Yq59VzFkDd4PJQaRCvw8uw2Lg",
        "hashed": "f1fe927281097ad39d6516531c69afc7de42ccd71daed9940d5e2d230e0c394d",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "XEM",
        "full_name": "NEM",
        "image": "ipfs://QmeZQCodFEoKamRizP7m3BNAgiBbqcrJTKxVmgbXhDGHo5",
        "hashed": "305ada5adfe9a48396098c0b1cff27c2e5c0f501cc851f666e915d50f790be78",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "BSV",
        "full_name": "Bitcoin SV",
        "image": "ipfs://QmaE3TDTKRUoup1KdePNw8BckUM18Sx5dmPqkxE82i4pWS",
        "hashed": "06acdc55d4df672224baa8e2f649ca85da098d08294bc81d06b3818655acd3c5",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DOT",
        "full_name": "Polkadot",
        "image": "ipfs://QmXzpseqMF3HDuzK284abrU6oZbRBonxZRwW4sR48e9KqW",
        "hashed": "06356f7d5fc0146a9dfa1c602501be4ec896e5560c4f8b3d60dc438b8523d32f",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "CELO",
        "full_name": "Celo",
        "image": "ipfs://QmfYsjvEnxTvhn9Tb3QmjNEyNaTezUVW573QgDesCkiXSz",
        "hashed": "458422e63930eb1dc9e14d8bde56bcd68f3ac1946fb3ef01e274d263cff22f91",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "SC",
        "full_name": "Siacoin",
        "image": "ipfs://QmTHNZ5mA5VyzqRCuLiHtcr2efX2JKKxP3QqgZh5D4mmPH",
        "hashed": "08ca9158a26d7aa3d8318fbdfd60fcc4f5c4947df2cc1d1848a866683d4c3e7f",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "SNT",
        "full_name": "Status",
        "image": "ipfs://QmWF9aCqYNYHBuWo9DxS8V4cjBmYrmCopFyEEFiEpGvPdi",
        "hashed": "740924bb7a256d5e609fd24cb9ad5fb6969028885ce1bffda7672578bf2acf28",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "COMP",
        "full_name": "Compound",
        "image": "ipfs://QmdQeZC14Ww73xiwuYxYAG2jh7werSQfJDgEY1XxKGrMUz",
        "hashed": "62f1ad4bbda5082b18b38d5110e0757274ec1f9361a8b39dfc47f0449c256dd3",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ICX",
        "full_name": "Icon",
        "image": "ipfs://QmT4kFGKPbPg2ZGvpCpULcAsEznvogeFQwroESYSG46S8U",
        "hashed": "ba1488958c6ffe35147cf092fdfd82faf8b4451f0716081a06197014ad4aeb6f",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "FIL",
        "full_name": "Filecoin",
        "image": "ipfs://QmPKempZeC8zZyHfXsV87mCHvz7DVhxHHaJbzELQd6DeRW",
        "hashed": "a02cb62880dd65878bab4d152c03d95990c6f15f0d53e8cc153e62a61d544477",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "MANA",
        "full_name": "Decentraland",
        "image": "ipfs://QmPatQrynox9Sf5o287zzeUUGBVfghnsrPh4EZ5XpmGqvw",
        "hashed": "e120d3575dded3f3e97382ea5ac05053199bbe26634b781b5b983aafaf1d0732",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "STORJ",
        "full_name": "Storj",
        "image": "ipfs://Qmaqp1eo3uC43hkgYRscFJ7bWyZAJya8ZudNhffvhUyCU7",
        "hashed": "cd56b5e32b1c855024d1c39642c77e1473061844e20eee6cc59d0911f20c27ce",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "SNX",
        "full_name": "Synthetix",
        "image": "ipfs://QmRuJmxivF4hop4yEEEFinUS9Z1AfKz17m2Udu8jvZRuP1",
        "hashed": "0ccf10db59c7eb2f214334616669c813db98ada72e79fa5f2d2d5dacf8bc2f90",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "CAKE",
        "full_name": "Pancake Swap",
        "image": "ipfs://QmeyNkZhy3LGhpPEc17716t3c4WiYnEbNyE352A2Jt6YJg",
        "hashed": "2004d95f40d23ae0504a23e921b9a9b016b92ffe282fa7db4bb4c0a6b623f1e6",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "POLY",
        "full_name": "Polymath",
        "image": "ipfs://Qmf4ntU4EmsEKB5zLmMCAtCJpU3gQeK2uATVfR15r6DLYK",
        "hashed": "196eb3cf500ec37db7a0789445669b451653f9e980d118c938d1c881c063b0e7",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ATOM",
        "full_name": "Cosmos",
        "image": "ipfs://Qmc1hg1UQA7cMcP6iiqPcUKrGPyNbVMjpmUWGuQGdYfAgB",
        "hashed": "f5b49a2b50222bfd6a34e6db7e2129a4be9542dd06a9d556b245f90808a81fe2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "QNT",
        "full_name": "Quant",
        "image": "ipfs://QmT2oKysn1aFGVaZKXP7VrvReYSwxFFBCtQnaoxVbQejzz",
        "hashed": "4f78f2359679614708b62b7e8c91f4a1ddda2030ce0898ec187f9478a6fd695d",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DOGE",
        "full_name": "Doge",
        "image": "ipfs://Qmd1AiSqfGFFMWjM4QDxYimZgHsohQigWp2MiwWUbwRPAb",
        "hashed": "5c8b68d8edd175546c39cd8b6e77c8a263e5deae0a989841ef3db65f3144bf52",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "XVG",
        "full_name": "Verge",
        "image": "ipfs://QmcYHvVg7cofG337n6Xhbtx48W8jZdUFU8uBL9air2kn9a",
        "hashed": "ab4ddb45d6ce305363118e672d0afe1b2eaefda071e4dfe5bf10352442049812",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "HNT",
        "full_name": "Helium",
        "image": "ipfs://QmZ2Y8dYeEJrarJbErpNcV68kR2VXKarFUXunhVGFbB6Fm",
        "hashed": "51822d9291a3d952ac31bdaae062789da6432c0fa9627d4f8f37c43392cc43cb",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "AVAX",
        "full_name": "Avalanche",
        "image": "ipfs://QmYyAE8bSYT9LrmHF5gwzstVAPYqdkubQ4EAJ6sBeK1G7i",
        "hashed": "c29502fc425d85ef3f3288f383ddbec66d365b5088324abe53d75a05ce712dfa",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "AAVE",
        "full_name": "Aave",
        "image": "ipfs://QmQAj5VBh1KRnJqx9GUUposbzLwYQnhmTAV76tAcWwkt4v",
        "hashed": "3486e788c87a4ecb1c94d757144b8f85de890770b8441b60dcab38fed4107e33",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "XPR",
        "full_name": "Proton",
        "image": "ipfs://QmSekG5XWM45ck2ve59Aiah4LPSeK59hSPJo8wJuuBdjdY",
        "hashed": "de51f196235f2319518ac8f0d27175e31752db8aad52472c3bba658707a9987e",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "SAND",
        "full_name": "Sandbox",
        "image": "ipfs://QmcrmthaHPncuzTcUx5Ef6QuNzdBFKzdNpXoYzyPNqavqE",
        "hashed": "0fcc42bcf7319dc5db7d254503aae74d38a4b986aed386b0781abb7505966b37",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "YFI",
        "full_name": "Yearn Finance",
        "image": "ipfs://QmfCf8v49VRucyAnucMaqY8dZ9SLtHCJzXqHqtXaKzFsHD",
        "hashed": "ad002972056a33f02b3bf006ca21122c9e05f5508f38b441869d2169f1d662e7",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ALGO",
        "full_name": "Algorand",
        "image": "ipfs://QmVFujvJmGS6C2qgsmcKo53Jc26j7W8GPQMYpvXPk2jLiT",
        "hashed": "af4b10df23db42571766f387313b1e5b1a894c939c480c84a6299fe39aede130",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ENJ",
        "full_name": "Enjin",
        "image": "ipfs://QmWA4SrZQPs97UJpLG8SsvxnLqezS5G43hcCPuLVWevL99",
        "hashed": "0a7147ce491f7b6be55f70cf8a42d5bb080c71677f7fc6ef85d6b8c78f973dcd",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "HOT",
        "full_name": "Holo",
        "image": "ipfs://QmP6bQeLh43t6VWJimyfJu5KZiiqHw79hPNVCfGWGSz9XT",
        "hashed": "f1397be2fc05ff705a9fc17a7c6b7aa078defa1794cdfef2f3b4aa7d1fb3db2d",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "TRX",
        "full_name": "Tron",
        "image": "ipfs://QmccZX7kugQqfyW4CRZXYwfx5CxbgMXREN7MvCMxW9Sorb",
        "hashed": "1ba339c8a28c798f6d7a283d53d0b3606d9257e51d2024d6606cf00110f653cd",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "STX",
        "full_name": "Stacks",
        "image": "ipfs://Qmd4XQy6VubEiy7jAupBGLZJ8zSrpXnu3nhKxsQoGYKZor",
        "hashed": "87b6ba2c74db839f32ef28230225a94cc665a1e007eb933162f86ccdbf2582f2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "DGB",
        "full_name": "Digibyte",
        "image": "ipfs://QmdCLy69kVCq9RUBQwesP9yoWVL3J3TK6UdeNnr5B7AKku",
        "hashed": "5df9cb16c9babb7e38d08c31c07c2d49c78d7f8b1edec6287dc51250a092cc43",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "CVC",
        "full_name": "Civic",
        "image": "ipfs://QmcC7qDD4SDo9qG7JBYGS8YoWq9KZa59BUEdr63Wr763e5",
        "hashed": "be0135ba520ae4ef0ff93f704240f58833990ba0178ed228da1748f4646b4ee2",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "BNT",
        "full_name": "Bancor",
        "image": "ipfs://QmRpP6NqPeqfzKa2Uq6EFp9JTTJiDrGzT7ats4Y7gFodZk",
        "hashed": "7170f1d2509f7ffe74a16a38ffb3f565c2c34557cc0210d39cfb75e9c233f946",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "MIR",
        "full_name": "Mirror protocol",
        "image": "ipfs://QmRGEdqD6eEd6dkgPZDmHkmyxcopqP1pWdHiXpa7QjogGy",
        "hashed": "f4106ad8257b3c7e328d991788a3307af51c37c8b2015bb1c4e9fe2ebaaf0c47",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "NANO",
        "full_name": "Nano",
        "image": "ipfs://QmSJ5Ush4XQCvdZqDeUrGVREhKo1LokzwgtV1x7nMftYJH",
        "hashed": "c63a23842df5dd6098da4e84449e84e35df6451ae262ceb0a1cd2015b3444bae",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "HT",
        "full_name": "Huobi",
        "image": "ipfs://QmPzfnzFACSWuqeBjjBakja35LngijngNstBqpsBo2K54C",
        "hashed": "7b886ad31758d9f63954253f20032a73c9ac87803b81658945e8756bb9dbb695",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ANT",
        "full_name": "Aragon",
        "image": "ipfs://QmW3Eac9fuLN8e819mok7XuQZSwnr2iVhNHvCxR6WHvi6Q",
        "hashed": "99f0777e042c8e28ea76ac035f843ac2a0e32d8435b12397debadefee9bd3dad",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "XRP",
        "full_name": "Ripple",
        "image": "ipfs://QmPWuT8LEgmaRZDHHFjsX5YBr8obPLpntsiL61NKJ2APbt",
        "hashed": "bc59fa1c0d77e069fb2f224ce13e46196eb3969549f0b2f3108404bbe8b3a738",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK1"
            },
            {
                "trait_type": "Eyes",
                "value": "3"
            },
            {
                "trait_type": "Environment",
                "value": "Spectrum"
            }
        ]
    },
    {
        "name": "FTM",
        "full_name": "Fantom",
        "image": "ipfs://QmTNptPfszBMuhUEpbpptFhPGfQr3zMfmzzRpSrtiNtyW2",
        "hashed": "c15cb17f094ff7178b26458fccd8f400b55632e5fae906e212ce37b83a5f7a08",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ZEC",
        "full_name": "Zcash",
        "image": "ipfs://QmZdcgYcPyBCtznULY7QR5tRbXpWEdyYBu6qAy4wMFqYxv",
        "hashed": "c989afad6cd2b9aa184f7b2441db491174ef8c0a418e033199e73c9d6af419df",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "NEO",
        "full_name": "Neo",
        "image": "ipfs://QmU6NM5gLomjMzqEMP1Giit1TA6u2ozH9BYcvztQEHSMm3",
        "hashed": "5b18c06aec2dd9392d995fc91ad798ef8e8db90269e9112526178aedab4f8089",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK2"
            },
            {
                "trait_type": "Eyes",
                "value": "2"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    },
    {
        "name": "ANKR",
        "full_name": "Ankr",
        "image": "ipfs://QmXgS9JhfZp3EtL2um6nSBFcitrvMic993428bnvQ9kQaQ",
        "hashed": "31e40a3bb35a7c0bed104213b23615081b8bfecfa2706ae91a0ff4a65614f31d",
        "attributes": [
            {
                "trait_type": "Achetype",
                "value": "MK3"
            },
            {
                "trait_type": "Eyes",
                "value": "1"
            },
            {
                "trait_type": "Environment",
                "value": "Grey"
            }
        ]
    }
]
		};
	}

	/*componentDidMount(){
		const listOfHashes = [];
			Array(100).fill().map(
				(key, value) => {
					axios.get(this.state.baseURI+value).then(function(res){
						listOfHashes.push(res.data)
						console.log(value, res)
						if(value == 99){
							console.log(listOfHashes)
						}
					}).catch(function(err){
						console.log(err)
					})
				}
			)
	}*/

	async loadData(){
		await this.loadWeb3()
		await this.loadBlockchainData()
	}

	async loadWeb3(){
	if(window.ethereum){
	  window.web3 = new Web3(window.ethereum)
	  await window.ethereum.enable()
	}
	else if(window.web3){
	  window.web3 = new Web3(window.web3.currentProvider)
	}
	else{
	  window.alert('Non-Ethereum browser detected. You should consider trying MetaMask !')
	}
	}

    async loadBlockchainData() {
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });

        const abi = CryptoPodz.abi;
        // Removed unused variable 'test_address'
        const main_address = "0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49";
        const address = main_address;
        var contract = new web3.eth.Contract(abi, address);

        this.setState({ contract: contract, address: address });
        const totalSupply = await contract.methods.totalSupply().call();
        const price = await contract.methods.podzPrice().call();
        console.log(totalSupply, price);
        this.setState({ totalSupply: totalSupply, price: price });

	    /*const saleTime = await contract.methods.saleFinishTime().call()
	    if(saleTime > new Date().getTime()/1000){
	    	const whitelist = await contract.methods.whitelistedAddresses(accounts[0]).call()
	    	if(whitelist){
	    		this.setState({whitelisted: true})	
	    	}else{
	    		this.setState({whitelisted: false})	
	    	}
	    }else{
	    	this.setState({whitelisted: true})
	    }
	    this.fetchMyDetails()*/
	  //}
	}

	mint = (numberOfTokens) => {
		this.state.contract.methods.mintPodz(numberOfTokens).send({from: this.state.account, value: this.state.price*numberOfTokens})
		.once('receipt', (receipt)=> {
		    console.log("minted")
		})
	}

	mintPod = (event) =>{
	    event.preventDefault();
	    const amount = event.target.radioGroup.value;

	    if(amount && this.state.contract){
	    	this.mint(amount);
	    }else{
	    	alert("Connect to your wallet and choose the number of tokens to mint");
	    	this.props.close();
	    }
	}

    fetchMyDetails = () => {
        const state = this.state;
        const thisS = this;
        // Removed unused variable 'testnet'
        const mainnet = "https://opensea.io/assets/";
        if (state.contract) {
            state.contract.methods.balanceOf(state.account).call()
                .then(function (balance) {
                    for (let i = 0; i < balance; i++) {
                        console.log(i);
                        state.contract.methods.tokenOfOwnerByIndex(state.account, i).call()
                            .then(function (tokenId) {
                                thisS.setState(prevState => ({ myTokens: [...prevState.myTokens, mainnet + state.address + "/" + tokenId] }));
                            });
                    }
                });
        }
    }

    render() {
        console.log(this.state, "TS");
        return (
            <div className={classes.AllPodz}>
                <div className={classes.Container}>
                    {
                        this.state.allHashes.map((value, index) => {
                            return <div className={classes.ImageContainer}>
                                <img src={value.image.replace("ipfs://", "https://ipfs.io/ipfs/")} alt={"cryptopodz - " + value.name} />
                                <div className={classes.ImageContent}>
                                    <div>{value.name}</div>
                                    <a href={value.image.replace("ipfs://", "https://ipfs.io/ipfs/")} target="_blank" rel="noreferrer">IPFS</a>
                                    <a href={"https://opensea.io/assets/0xe29a492bbf25f86b1340d7b9e440b1cda0ec3c49/" + index} target="_blank" rel="noreferrer">OPENSEA</a>
                                </div>
                            </div>
                        })
                    }
                </div>
    		{/* this.state.maxDisplay <= 99 ?
    		<div className={classes.LoadButton}>
    			<button onClick={()=>this.setState({maxDisplay: this.state.maxDisplay+10})}>Load More</button>
    		</div> : null */}
            <div className={classes.TextContainer}>
                <div>Displayed in the randomized minting order.</div>
            </div>
    	</div>
    	);
  }
}
			/*<div className={classes.MintForm}>
				{ this.state.contract ?
				this.state.formShow && this.state.myTokens.length <= 2 ?
				this.state.totalSupply < 100 ?
					this.state.whitelisted ?
						<form onSubmit={this.mintPod}>
							<div>
								<input type="radio" id="podzInput1" name="radioGroup" value="1" />
								<label for="podzInput1">1 Pod</label>
							</div>
							{ this.state.myTokens.length == 1 || this.state.totalSupply == 99 ?
								null :
							<div>
								<input type="radio" id="podzInput2" name="radioGroup" value="2" />
								<label for="podzInput2">2 Podz</label>
							</div> }
							<div className={classes.SubmitButton}>
								<button type="submit">MINT</button>
							</div>
						</form>
					: <div className={classes.NotWhitelisted}>User not whitelisted</div>
				: <div className={classes.CheckOpensea}>
						All tokens have been minted. Please check the collection on&nbsp;
						<a href="https://opensea.io/collection/thecryptopodz" target="_blank">Opensea</a>
					</div>
				: null
				:	<div className={classes.SubmitButton}>
					<button onClick={()=>this.loadData()}>Connect to wallet</button>
				</div> }

				{
					!this.state.formShow && this.state.contract && this.state.myTokens.length == 0 ? <div className={classes.SubmitButton} onClick={()=>this.setState({formShow: true})}>
																																		<button>MINT</button>
																																	</div>
					: !this.state.formShow && this.state.contract && this.state.myTokens.length == 1 ? <div className={classes.SubmitButton} onClick={()=>this.setState({formShow: true})}>
																																			<button>MINT ANOTHER</button>
																																		</div>
					: null
				}


				{ this.state.myTokens.length ?
					<div className={classes.MyTokens}>
						Thank you for your purchase.<br />
						All the metadata will be available on the website shortly after the sale.<br />
						{/*You may view the CryptoPodz collection on <a href="#">OpenSea</a>.*}<br /><br />
						Minted Podz
						{
							this.state.myTokens.map(function(data, index){
								return <div><a href={data}>{"Pod "+(index+1)}</a></div>;
							})
						}
						{/*<div className={classes.PS}>**You may view the CryptoPodz collection on OpenSea. All of the Podz will be displayed on the website soon.</div>*}
					</div> : null
				}
			</div>*/

export default BuyFunction;