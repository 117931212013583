import Header from '../Header/Header';
import SectionOne from '../SectionOne/SectionOne';
import SectionTwo from '../SectionTwo/SectionTwo';
import SectionThree from '../SectionThree/SectionThree';
import SectionFour from '../SectionFour/SectionFour';
import SectionNine from '../SectionNine/SectionNine';

const Home = () => {
  return (
    <div>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionFour/>
      <SectionThree/>
      <SectionNine/>
    </div>
  );
}

export default Home;
