import './App.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Home from './components/LandingPage/HomePage/Home';
import BattlePodzHome from './components/BattlePodz/HomePage/Home';
import Archive from './components/LandingPage/Archive/Archive';
import PodzWorld from './components/PodzWorld/HomePage/Home';
import AccountPage from './components/PodzWorld/Account/AccountPage'; // Import the MainPage component

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/archive" component={Archive} />
          <Route exact path="/battlepodz" component={BattlePodzHome} />
          <Route exact path="/podzworld" component={PodzWorld} />
          <Route exact path="/podzworld/signup/:token" component={AccountPage} /> {/* Dynamic route for signup */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;