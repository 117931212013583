import React from "react";
import classes from './SectionThree.module.css';

import PodzCoin from '../../../assets/images/BattlePodz/Element4.gif';

const SectionThree=()=>{
    const Render=[
        {
            title:"EXPLORE",
            content:"PodzWorld is set in a strange desert filled with various elements and artifacts rooted in this fictionalized blockchain lore. Journey into the desert or roam the city metropolis and its various venues filled with medias and items from across the Metaverse."
        },
        {
            title:"BATTLE",
            content:"The world is filled with oppertunities for combat, whether it be the enemy AI lurking in the dune seas, or the online players partaking in duels inside the arena."
        },
        {
            title: "LEARN",
            content: "Introductory knowledge has been scattered throughout the world in the form of 3D pyramids called Lexicons, gather them to progress your codex and level."
        },
        //{
        //    title:"CRAFT",
        //    content:"The world is formed from the basic 5 elements of PodzWorld; Etherstone, Bitrock, Polyplasm, Metanium and Satogen. Collect and combine them to create various items and products."
        //},
        {
            title:"SOCIALIZE",
            content:"Speak to other collectors using the in-game chat system, create private parties, and meet up in exclusive venues inside the metropolis."
        },
        {
            title:"EARN REWARDS",
            content:"By collecting the currency of the world, PodzCoin and finding various easter eggs, you may become elligible for special NFT rewards."
        }
    ]

    return(
    <div className={classes.SectionThree}>
        <div className={classes.Container} id="section3">
            <div className={classes.ParaContainer}>
                <p>PILLARS OF THE WORLD</p>
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
            <div className={classes.ContentRendering}>
                {Render.map((item, index)=>{
                         return <div className={classes.ObjectContainer}>
                              <p>{item.title}</p>
                              <p>{item.content}</p>
                              </div>
                    })}
            </div>
            <div className={classes.Ruler}>
                <hr></hr>
                <hr></hr>
            </div>
            <div className={classes.PodzCoin}>
                {<img src={PodzCoin} alt="PodzCoin"/>}
            </div>
        </div>
    </div>
    )
}
export default SectionThree