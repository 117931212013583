import React from 'react';
import classes from './SectionTwo.module.css';

const SectionTwo = () => {
	return (
		<div className={classes.SectionTwo} id="section2">
			<div className={classes.Container}>
				<div className={classes.Text}>
					<div className={classes.BuyPodOpenSea}>
						<div>BUY A POD</div>
						<div>The initial sale has sold out. To get your Crypto Pod, check out the collection on OpenSea.</div>
						<div>
							<a href="https://opensea.io/collection/thecryptopodz" target="_blank" rel="noreferrer">
								<button>
									VIEW ON OPENSEA
								</button>
							</a>
						</div>
					</div>
					<div className={classes.PodzWar}>
						<div className={classes.WarImg}>
							<div
								style={{padding: "95% 0 0 0", position:"relative"}}>
								<iframe
								src="https://player.vimeo.com/video/604774073?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=54c1c79881"
								frameborder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%", maxHeight: "280px"}}
								title="Teaser.mp4">
								</iframe>
							</div>
						</div>
						<div className={classes.WarText}>
							<div>THE WAR OF THE PODZ</div>
							<div>CryptoPodz have arrived. These little creatures were created by a race of sentient alien robots in the far reaches of the cosmos. They have observed us here on earth, and have sent probes containing blueprints to create a better future. They must compete or collaborate in order to evolve. This is a social experiment, not all blueprints are guaranteed success.</div>
							<div>Nonetheless, as a collective they will succeed in their mission to bring upgraded decentralized systems to humans.</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.Image}>
				<div className={classes.ImageContainer}>
						{/*<img src={LandscapeShowcase} />*/}
				</div>
			</div>
		</div>
	);
}

export default SectionTwo;